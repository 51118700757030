var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex p-0",attrs:{"lg":"8","md":"6"}},[_c('SplashScreen')],1),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"lg":"12","md":"6","sm":"8"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" MyFlightConnect ")]),_c('validation-observer',{ref:"loginValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();validate().then(_vm.submitCredentials)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('login.email'),"label-for":"login-email"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":""},model:{value:(_vm.credentials.email),callback:function ($$v) {_vm.$set(_vm.credentials, "email", $$v)},expression:"credentials.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',[_c('label',{staticClass:"d-flex justify-content-between col-12 p-0",attrs:{"for":"login-password"}},[_vm._v(" "+_vm._s(_vm.$t('login.password'))+" "),_c('b-link',{attrs:{"to":{ name: 'forgot-password' }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('login.forgot_password')))])])],1)]),_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('login.password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"disabled":invalid,"block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('action.sign_in'))+" ")])],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('login.new_on_platform')))]),_c('b-link',{attrs:{"to":{ name: 'register' }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('login.create_an_account')))])])],1),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('login.have_question')))]),_c('b-link',{attrs:{"to":{ name: 'contact' }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('login.contact_us')))])])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }